import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtTextInput from "./NtTextInput";
import NtText from "./NtText";

function NtModalInputField({
  containerStyle,
  title,
  placeholder,
  value,
  setValue,
  titleStyle,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText
        style={[styles.title, { marginLeft: 10, marginBottom: 4 }, titleStyle]}
      >
        {title}
      </NtText>
      <NtTextInput
        textStyle={{ flex: 1 }}
        containerStyle={[styles.input]}
        placeholder={placeholder}
        placeholderTextColor={colors.lighGray}
        value={value}
        autoCorrect={false}
        autoCapitalize="none"
        onChangeText={(text) => setValue(text)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: colors.lighestGray,
    borderRadius: 30,
  },
});

export default NtModalInputField;
