import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtCard from "./NtCard";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import NtDevider from "./NtDevider";
import NtText from "./NtText";

function NtDropDownSelectContainer({
  containerStyle,
  data,
  title,
  selection,
  onSelect,
  placeholder = "Make a selection",
}) {
  const [open, setOpen] = useState(false);

  const renderRow = (item) => {
    return (
      <NtTouchableEffect
        key={item.title}
        style={{ alignItems: "center" }}
        onPress={() => {
          if (onSelect) {
            onSelect(item);
            setOpen(!open);
          }
        }}
      >
        <View style={{ paddingTop: 10, paddingBottom: 10 }}>
          <NtText>{item.title}</NtText>
        </View>
        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <div
      className="overlay"
      style={{ justifyContent: "center", ...containerStyle }}
    >
      <NtText style={[styles.title, { marginLeft: 10 }]}>{title}</NtText>
      <NtTouchableEffect
        style={[
          styles.input,
          {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          },
        ]}
        onPress={() => {
          setOpen(!open);
        }}
      >
        <NtText>{selection?.title || placeholder}</NtText>
        <NtMaterialIcon name={open ? "chevron-up" : "chevron-down"} />
      </NtTouchableEffect>
      {open && (
        <View style={styles.dropdown}>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
            showsHorizontalScrollIndicator={false}
          >
            {data?.map((each) => renderRow(each))}
          </ScrollView>
        </View>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: colors.lighestGray,
    borderRadius: 30,
    marginBottom: -6,
  },
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
  dropdown: {
    position: "absolute",
    zIndex: 5,
    marginTop: 6,
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.26,
    elevation: 1,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    marginLeft: 4,
    marginRight: 4,
    minWidth: 140,
    maxHeight: 200,
    // left: 30,
    // right: 30,
    // alignItems: "center",
  },
});

export default NtDropDownSelectContainer;
