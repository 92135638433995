//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import routes from "./routes";
import DashboardController from "../dashboard/controllers/DashboardController";
import MarketingController from "../marketing/controllers/MarketingController";
import VoicemailController from "../maritime/controllers/VoicemailController";
import MarketingHealthController from "../marketing/controllers/MarketingHealthController";
import MarketingCrewAppController from "../marketing/controllers/MarketingCrewAppController";
import MarketingGuestAppController from "../marketing/controllers/MarketingGuestAppController";
import MarketingPBXSystemController from "../marketing/controllers/MarketingPBXSystemController";
import MessageV2Controller from "../maritime/controllers/MessageV2Controller";
import ExtensionManagementController from "../extensions/controllers/ExtensionManagementController";
import GatewayReportController from "../extensions/controllers/GatewayReportController";
import AppAccessController from "../extensions/controllers/AppAccessController";
import FeatureCodeController from "../common/controllers/FeatureCodeController";
import VVNController from "../vvn/controllers/VVNController";
import MarketingHospitalityPBXController from "../marketing/controllers/MarketingHospitalityPBXController";
import DocumentController from "../docs/controllers/DocumentController";
import PinManagementController from "../vvn/controllers/PinManagementController";
import PinHistoryController from "../vvn/controllers/PinHistoryController";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.dashboard}
        component={DashboardController}
        options={{ title: "MARITIME | Dashboard" }}
      />

      <Stack.Screen
        name={routes.messagev2}
        component={MessageV2Controller}
        options={{ title: "MARITIME | Message" }}
      />

      <Stack.Screen
        name={routes.voicemail}
        component={VoicemailController}
        options={{ title: "MARITIME | Voicemail" }}
      />

      <Stack.Screen
        name={routes.vvn}
        component={VVNController}
        options={{ title: "MARITIME | Voice Vendor Neutral" }}
      />

      <Stack.Screen
        name={routes.pinmanagement}
        component={PinManagementController}
        options={{ title: "MARITIME | Pin Management" }}
      />

      <Stack.Screen
        name={routes.pinhistory}
        component={PinHistoryController}
        options={{ title: "MARITIME | Pin History" }}
      />

      <Stack.Screen
        name={routes.extensionmanagement}
        component={ExtensionManagementController}
        options={{ title: "MARITIME | Extension Report" }}
      />

      <Stack.Screen
        name={routes.gatewayreport}
        component={GatewayReportController}
        options={{ title: "MARITIME | Gateway Report" }}
      />

      <Stack.Screen
        name={routes.appaccess}
        component={AppAccessController}
        options={{ title: "MARITIME | App Access" }}
      />

      <Stack.Screen
        name={routes.docs}
        component={DocumentController}
        options={{ title: "MARITIME | Documentation" }}
      />

      <Stack.Screen
        name={routes.featurecode}
        component={FeatureCodeController}
        options={{ title: "MARITIME | Feature Code" }}
      />

      <Stack.Screen
        name={routes.marketing}
        component={MarketingController}
        options={{ title: "MARITIME | Marketing" }}
      />

      <Stack.Screen
        name={routes.health}
        component={MarketingHealthController}
        options={{ title: "MARITIME | Health" }}
      />

      <Stack.Screen
        name={routes.crewapp}
        component={MarketingCrewAppController}
        options={{ title: "MARITIME | Crew App" }}
      />

      <Stack.Screen
        name={routes.guestapp}
        component={MarketingGuestAppController}
        options={{ title: "MARITIME | Guest App" }}
      />

      <Stack.Screen
        name={routes.pbxsystem}
        component={MarketingPBXSystemController}
        options={{ title: "MARITIME | PBX System" }}
      />

      <Stack.Screen
        name={routes.hospitalitypbx}
        component={MarketingHospitalityPBXController}
        options={{ title: "MARITIME | Hospitality PBX" }}
      />

      <Stack.Screen
        name={routes.starlink}
        component={MarketingController}
        options={{ title: "MARITIME | Starlink" }}
      />

      <Stack.Screen
        name={routes.notFound}
        component={NoFoundController}
        options={{ title: "MARITIME | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
