import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "../../common/utils/ControllerUtils";
import NtAdjustColumnsButton from "../components/NtAdjustColumnsButton";

class BasePinController extends BaseController {
  state = {
    columns: null,
  };

  handleDefaultColumnsFetch = async (location, defaultValue) => {
    let columns = await asyncStorageGetData(location);
    columns = JSON.parse(columns);

    if (columns === null) {
      columns = defaultValue;
    }

    this.setState({ columns: columns });
  };

  renderAdjustColumnsButton = (type = "pin") => {
    if (this.state.data?.length == 0) {
      return null;
    }

    return (
      <View style={{ alignItems: "flex-end", marginTop: 10 }}>
        <NtAdjustColumnsButton
          columns={this.state.columns}
          onUpdate={(updated) => {
            asyncStorageSetData(this.column_location, updated);
            this.setState({ columns: updated });
          }}
          type={type}
        />
      </View>
    );
  };
}

const styles = StyleSheet.create({
  container: {},
});

export default BasePinController;
