import React, { useState, useRef, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtDownloadCSVButtonFieldPicker from "./NtDownloadCSVButtonFieldPicker";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import { fetchCdrs, fetchPins } from "../../api/billing";
import UserContext from "../../context/UserContext";

function NtDownloadCSVButton({ containerStyle, type = "pin", search, filter }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fieldsSelectRef = useRef();
  const context = useContext(UserContext);

  const handleDataFetch = async () => {
    setLoading(true);
    let response = null;

    if (type == "pin") {
      response = await fetchPins(
        context.user?.environment?.domain,
        1,
        100000,
        search,
        filter
      );

      if (response.ok) {
        setData(response.data?.pins);
        fieldsSelectRef.current?.show();
      }
    } else {
      if (type == "cdr") {
        response = await fetchCdrs(
          context.user?.environment?.domain,
          1,
          100000,
          search,
          filter
        );
      }
      if (response.ok) {
        setData(response.data?.cdrs);
        fieldsSelectRef.current?.show();
      }
    }
    setLoading(false);
  };

  const renderFieldsSelect = () => {
    return (
      <NtModal
        ref={fieldsSelectRef}
        renderBody={
          <NtDownloadCSVButtonFieldPicker
            onCancel={() => {
              fieldsSelectRef.current?.dismiss();
            }}
            data={data}
            type={type}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        handleDataFetch();
      }}
    >
      {loading && (
        <NtActivityIndicator
          containerStyle={{ marginRight: 8 }}
          size={15}
          color="white"
        />
      )}
      <NtText style={styles.text}>Download .csv</NtText>

      {renderFieldsSelect()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.blue,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 20,
  },
  text: {
    color: "white",
    fontSize: 13,
    fontWeight: "600",
  },
});

export default NtDownloadCSVButton;
