import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtUnderConstruction from "../../components/NtUnderConstruction";
import BaseController from "../../common/base/BaseController";

class DocumentController extends BaseController {
  state = {};
  render() {
    return (
      <NtRootPageContainer
        style={styles.container}
        showTitleView={false}
        showFooter={true}
      >
        <NtUnderConstruction containerStyle={{ flex: 1 }} />
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default DocumentController;
