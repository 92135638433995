import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useMobile } from "../../hooks/useMobile";
import NtRootPageContainerHeader from "./NtRootPageContainerHeader";
import NtBackButton from "./NtBackButton";
import NtRootPageFooter from "./NtRootPageFooter";
import NtDevider from "../../components/NtDevider";
import NtSearchBar from "../../components/NtSearchBar";

function NtRootPageContainer({
  children,
  pageTitle,
  pageSubtitle,
  showTitleView = true,
  showFooter = false,
  showBackButton = false,
  scrollEnabled = true,
  searchText,
  searchFilterCount,
  setSearchText,
  onSearchFilterPress,
  containerStyle,
  maxWidth = 850,
}) {
  const isMobile = useMobile();

  return (
    <View style={[styles.container, containerStyle]}>
      <ScrollView
        scrollEnabled={scrollEnabled}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View
          style={{ flex: 1, justifyContent: "center", flexDirection: "row" }}
        >
          <View
            style={{
              padding: isMobile ? 15 : 20,
              flex: 1,
              maxWidth: maxWidth,
            }}
          >
            {/* title view */}
            {showTitleView && (
              <View>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    },
                    isMobile
                      ? {
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }
                      : {},
                  ]}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {showBackButton && (
                      <NtBackButton containerStyle={{ marginRight: 10 }} />
                    )}

                    <NtRootPageContainerHeader
                      title={pageTitle}
                      subtitle={pageSubtitle}
                      titleSize={isMobile ? 20 : 27}
                      subtitleSize={isMobile ? 18 : 18}
                    />
                  </View>

                  {setSearchText && (
                    <NtSearchBar
                      containerStyle={[
                        { marginLeft: 60, flex: 1, maxWidth: 400 },
                        isMobile
                          ? { marginTop: 10, marginLeft: 0, minWidth: "100%" }
                          : {},
                      ]}
                      filterCount={searchFilterCount}
                      value={searchText}
                      onChangeText={(text) => {
                        if (setSearchText) {
                          setSearchText(text);
                        }
                      }}
                      onFilterPress={onSearchFilterPress}
                    />
                  )}
                </View>
                <NtDevider containerStyle={{ marginTop: 15 }} />
              </View>
            )}

            {children}
          </View>
        </View>

        {showFooter && <NtRootPageFooter />}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    backgroundColor: "white",
  },
});

export default NtRootPageContainer;
