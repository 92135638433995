const cdrcsvcolumndata = [
  {
    id: "id",
    title: "Id",
  },
  {
    id: "uniqueid",
    title: "Call ID",
  },
  {
    id: "username",
    title: "Pin",
    default: true,
  },
  {
    id: "name",
    title: "Name",
  },
  {
    id: "status",
    title: "Status",
  },
  {
    id: "tag",
    title: "Type",
  },
  {
    id: "real_sessiontime",
    title: "Duration",
  },
  {
    id: "sessiontime",
    title: "Billed Time",
    default: true,
  },
  {
    id: "src",
    title: "From",
    default: true,
  },
  {
    id: "dnid",
    title: "To",
    default: true,
  },
  {
    id: "terminatecauseid",
    title: "Termination",
    default: true,
  },
  {
    id: "starttime",
    title: "Start Time",
    default: true,
  },
  {
    id: "stoptime",
    title: "End Time",
    default: true,
  },
  {
    id: "buycost",
    title: "Price",
  },
];
export default cdrcsvcolumndata;
