import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import NtPinFormTab from "./NtPinFormTab";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import terminationcausedata from "../data/terminationcausedata";
import pintypesdata from "../data/pintypesdata";

function NtPinCdrAdvanceSearchPicker({
  onCancel,
  onFilter,
  onFilterClear,
  filter,
}) {
  const context = useContext(UserContext);
  const [callid, setCallid] = useState(
    filter?.uniqueid ? filter?.uniqueid : ""
  );
  const [terminationCause, setTerminationCause] = useState(
    terminationcausedata.find((each) => each.id == filter?.terminatecauseid)
  );
  const [tag, setTag] = useState(
    pintypesdata.find((each) => each.id == filter?.tag)
  );
  const [dateFrom, setDateFrom] = useState(filter?.dateFilterFrom);
  const [dateTo, setDateTo] = useState(filter?.dateFilterTo);

  const renderTerminateCauseIdTab = (item) => {
    const isSelected = item.id == terminationCause?.id;
    return (
      <NtPinFormTab
        key={item.id}
        title={item.title}
        onPress={() => {
          if (terminationCause?.id == item.id) {
            setTerminationCause(null);
          } else {
            setTerminationCause(item);
          }
        }}
        selected={isSelected}
      />
    );
  };

  const renderPinTypeTab = (item) => {
    const isSelected = item.id == tag?.id;
    return (
      <NtPinFormTab
        key={item.id}
        title={item.title}
        onPress={() => {
          if (tag?.id == item.id) {
            setTag(null);
          } else {
            setTag(item);
          }
        }}
        selected={isSelected}
      />
    );
  };

  const renderTerminationCause = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Termination Cause
        </NtText>
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 4 }}
        >
          {terminationcausedata.map((each) => renderTerminateCauseIdTab(each))}
        </View>
      </View>
    );
  };

  const renderPinTypes = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Pin Type</NtText>
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 4 }}
        >
          {pintypesdata.map((each) => renderPinTypeTab(each))}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Advance Search</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select Specific parameters for search
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Call-ID"}
        placeholder={"Enter Call Id"}
        value={callid}
        setValue={(text) => setCallid(text)}
      />

      {renderTerminationCause({ marginTop: 20 })}

      {renderPinTypes({ marginTop: 20 })}

      <View
        style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
      >
        <NtModalDatePickerField
          containerStyle={{ flex: 1 }}
          title={"From Date"}
          placeholder={"Enter From Date"}
          isTimeEnabled={true}
          value={dateFrom}
          setValue={(text) => setDateFrom(text)}
        />

        <NtModalDatePickerField
          containerStyle={{ flex: 1, marginLeft: 15 }}
          title={"To Date"}
          placeholder={"Enter To Date"}
          isTimeEnabled={true}
          value={dateTo}
          setValue={(text) => setDateTo(text)}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton
            title={"Clear filters"}
            onPress={() => {
              setCallid("");
              setTerminationCause(null);
              setDateFrom(null);
              setDateTo(null);

              if (onFilterClear) {
                onFilterClear();
              }
            }}
          />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Search"}
            enabled={true}
            onPress={() => {
              const filter = {};
              if (callid) {
                filter.uniqueid = callid;
              }
              if (terminationCause) {
                filter.terminatecauseid = terminationCause?.id;
              }
              if (tag) {
                filter.tag = tag.id;
              }
              if (dateFrom) {
                filter.dateFilterFrom = dateFrom;
              }
              if (dateTo) {
                filter.dateFilterTo = dateTo;
              }

              if (onFilter) {
                onFilter(filter);
              }
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinCdrAdvanceSearchPicker;
