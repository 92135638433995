import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtPinItem from "./NtPinItem";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import { parseDisplayDate } from "../../common/utils/ControllerUtils";
import terminationcausedata from "../data/terminationcausedata";
import Logger from "../../common/utils/Logger";
import { Overflow } from "baseui/icon";

function NtPinCdrItem({ item, columns }) {
  const findTermination = (id) => {
    const item = terminationcausedata.find((each) => each.id == id);

    return item?.title || "Unknown";
  };

  const renderRow = (column) => {
    if (column.id == "username") {
      // this is for pin.....
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtPinItem number={item[column.id]} showStatus={false} />
        </View>
      );
    } else if (column.id == "starttime" || column.id == "stoptime") {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtText style={[styles.text]}>
            {parseDisplayDate(item[column.id])}
          </NtText>
        </View>
      );
    } else if (column.id == "terminatecauseid") {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtText style={styles.text}>
            {findTermination(item[column.id])}
          </NtText>
        </View>
      );
    } else if (column.id == "uniqueid") {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtText
            style={[
              styles.text,
              {
                maxWidth: 100,
              },
            ]}
          >
            {item[column.id]}
          </NtText>
        </View>
      );
    }

    return (
      <View
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        key={column.id}
      >
        <NtText style={[styles.text]}>{item[column.id]}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {columns?.map((each) => renderRow(each))}
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default NtPinCdrItem;
