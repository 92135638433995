import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";

function NtPinManagementItemHeader({ columns }) {
  const renderRow = (item) => {
    return (
      <View
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        key={item.title}
      >
        <NtText style={styles.text}>{item.title}</NtText>
      </View>
    );
  };

  return (
    <NtTouchableEffect style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
          marginRight: 35,
        }}
      >
        {columns?.map((each) => renderRow(each))}
      </View>
      <NtDevider />
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.lighGray,
  },
});

export default NtPinManagementItemHeader;
