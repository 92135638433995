import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";

import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import {
  handlePinUploadCreate,
  handlePinUploadFailure,
} from "../utils/PinUploadParser";

function NtPinmanagementUploadForm({ onCancel, onUploaded }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const context = useContext(UserContext);

  const handleUpload = async () => {
    setLoading(true);
    const response = await handlePinUploadCreate(
      file,
      context.user?.environment
    );
    setLoading(false);
    setUploadResponse(response);
  };

  const handleDone = () => {
    if (onUploaded) {
      onUploaded(uploadResponse);
    }
  };

  const handleFailureDownload = async () => {
    const response = await handlePinUploadFailure(uploadResponse.failure);
    const fileData = response.data;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "failure-uploaded-appaccess.txt";
    link.href = url;
    link.click();
  };

  const isRequiredFields = () => {
    return file;
  };

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  const renderChooseCSV = () => {
    if (file) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          document.getElementById("selectFile").click();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "400", color: colors.blue }}>
          Choose CSV file
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderInfoCSV = () => {
    if (!file) {
      return null;
    }
    return (
      <View>
        <NtText>{file?.name}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Upload csv Pin List</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will allow to upload a bulk amount. columns MUST be named (case
        sensitive):{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>
          number
        </NtText>
        ,{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>name</NtText>,{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>
          expirationdate
        </NtText>
        ,{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>type</NtText>,
        and{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>
          credit
        </NtText>
      </NtText>

      <View
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: colors.lightest,
          backgroundColor: colors.lighestGray,
          justifyContent: "center",
          padding: 10,
          marginTop: 30,
        }}
      >
        {renderChooseCSV()}
        {renderInfoCSV()}
      </View>
      {uploadResponse && (
        <View
          style={{
            marginTop: 6,
          }}
        >
          <NtText style={[styles.uploadResponseText]}>
            Total: {uploadResponse?.total}
          </NtText>
          <NtText style={[styles.uploadResponseText, { marginTop: 4 }]}>
            Success: {uploadResponse?.success?.length}
          </NtText>
          <NtTouchableEffect
            style={{ marginTop: 4 }}
            onPress={() => {
              if (uploadResponse?.failure?.length > 0) {
                handleFailureDownload();
              }
            }}
          >
            <NtText style={[styles.uploadResponseText, { color: "red" }]}>
              Error: {uploadResponse?.failure?.length}
            </NtText>
          </NtTouchableEffect>
        </View>
      )}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={uploadResponse ? "Done" : "Upload"}
          onPress={
            isRequiredFields()
              ? () => {
                  if (uploadResponse) {
                    handleDone();
                  } else {
                    handleUpload();
                  }
                }
              : null
          }
          enabled={isRequiredFields()}
        />
      </View>

      <input
        id="selectFile"
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={changeHandler}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.lighGray,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtPinmanagementUploadForm;
