import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";
import NtMaterialIcon from "./NtMaterialIcon";

function NtModalActiveButton({
  enabled,
  onPress,
  title,
  containerStyle,
  icon,
}) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        {
          backgroundColor: enabled ? colors.blue : colors.lighGray,
        },
        containerStyle,
      ]}
      onPress={onPress}
    >
      {icon && (
        <NtMaterialIcon
          name={icon}
          size={14}
          color="white"
          style={{ marginRight: 4 }}
        />
      )}
      <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
        {title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
});

export default NtModalActiveButton;
