import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtUnderConstruction from "../../components/NtUnderConstruction";

class VVNController extends React.Component {
  state = {};
  render() {
    return (
      <NtRootPageContainer showTitleView={false} showFooter={true}>
        <NtUnderConstruction containerStyle={{ flex: 1 }} />
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default VVNController;
