import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtModal from "../../components/NtModal";
import Logger from "../../common/utils/Logger";
import colors from "../../config/colors";
import NtPinManagementNewForm from "../components/NtPinManagementNewForm";
import NtPinmanagementUploadForm from "../components/NtPinManagementUploadForm";
import NtDownloadCSVButton from "../components/NtDownloadCSVButton";
import { fetchPins } from "../../api/billing";
import NtPinManagementItem from "../components/NtPinManagementItem";
import NtPinManagementItemHeader from "../components/NtPinManagementItemHeader";
import {
  asyncStorageGetData,
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtPinManagementTabItem from "../components/NtPinManagementTabItem";
import settings from "../../config/settings";
import NtAdjustColumnsButton from "../components/NtAdjustColumnsButton";
import pincsvcolumndata from "../data/pincsvcolumdata";
import BasePinController from "./BasePinController";

class PinManagementController extends BasePinController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    selectedTab: null,
  };

  column_location = "@pin_column_storage";

  page = 1;

  infiniteScrollEnabled = false;
  addAccessModalRef = React.createRef();
  uploadAccessModalRef = React.createRef();
  updateAccessModalRef = React.createRef();

  tabs = [
    {
      id: "all",
      title: "All",
    },
    {
      id: "active",
      title: "Active",
    },
    {
      id: "inactive",
      title: "Inactive",
    },
  ];

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.state.selectedTab = this.tabs[0];
    this.setState({ selectedTab: this.state.selectedTab });
    this.handleDefaultColumnsFetch(
      this.column_location,
      pincsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    Logger("Fetching Pins data");

    this.setState({ loading: true });

    const response = await fetchPins(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      { status: this.state.selectedTab?.id }
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.pins?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.pins,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.pins),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAddNewForm = () => {
    return (
      <NtModal
        ref={this.addAccessModalRef}
        renderBody={
          <NtPinManagementNewForm
            onCancel={() => {
              this.addAccessModalRef.current?.dismiss();
            }}
            onCreate={() => {
              this.page = 1;
              this.handleDataFetch();
              this.addAccessModalRef.current?.dismiss();
              showToast("Success", "Successfully created the new pin");
            }}
          />
        }
      />
    );
  };

  renderUploadForm = () => {
    return (
      <NtModal
        ref={this.uploadAccessModalRef}
        renderBody={
          <NtPinmanagementUploadForm
            onCancel={() => {
              this.uploadAccessModalRef.current?.dismiss();
            }}
            onUploaded={() => {
              this.page = 1;
              this.handleDataFetch();
              this.uploadAccessModalRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  renderTabs = () => {
    return (
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 40,
            }}
          >
            {this.tabs.map((each, index) => (
              <NtPinManagementTabItem
                containerStyle={{ marginRight: 10 }}
                key={index}
                item={each}
                isSelected={each?.title == this.state.selectedTab?.title}
                onPress={() => {
                  if (each.id == this.state.selectedTab?.id) {
                    //no need to reload since we are not changing tabs
                    return;
                  }

                  this.state.selectedTab = each;
                  this.setState({
                    selectedTab: this.state.selectedTab,
                    searchText: "",
                    data: [],
                    master: [],
                  });
                  this.page = 1;
                  this.handleDataFetch();
                }}
              />
            ))}
          </View>
        </ScrollView>
      </View>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtPinManagementItem
        key={item.id}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          this.updateAccessModalRef.current?.dismiss();
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Pin Management"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        {this.renderTabs()}
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtText style={styles.title}>
            Records Found {this.state.totalItems}
          </NtText>

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
          <NtPageActionButton
            icon={"arrow-up"}
            onPress={() => {
              this.uploadAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 10 }}
          />

          <NtDownloadCSVButton
            containerStyle={{ marginLeft: 20 }}
            search={this.state.searchText}
            filter={{ status: this.state.selectedTab?.id }}
          />
        </View>

        {this.renderAdjustColumnsButton()}

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          {this.state.data?.length > 0 && (
            <NtPinManagementItemHeader columns={this.state.columns} />
          )}
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderUploadForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default PinManagementController;
