const pintypesdata = [
  {
    id: "admin",
    title: "Admin",
  },
  {
    id: "prepaid",
    title: "Prepaid",
  },
  {
    id: "courtesy",
    title: "Courtesy",
  },
  {
    id: "cabin",
    title: "Cabin",
  },
];
export default pintypesdata;
