import React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import NtTextInput from "./NtTextInput";
import NtTouchableEffect from "./NtTouchableEffect";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";
import NtText from "./NtText";

function NtSearchBar({
  containerStyle,
  filterCount = 0,
  onFilterPress,
  ...otherProps
}) {
  const renderDelete = (containerStyle) => {
    if (!otherProps?.value) {
      return null;
    }

    return (
      <NtTouchableEffect
        style={[
          { alignItems: "center", justifyContent: "center" },
          containerStyle,
        ]}
        onPress={() => {
          if (otherProps.onChangeText) {
            otherProps.onChangeText("");
          }
        }}
      >
        <NtMaterialIcon name={"delete"} color={colors.almostBlack} />
      </NtTouchableEffect>
    );
  };

  const renderFilter = (containerStyle) => {
    if (!onFilterPress) {
      return null;
    }

    const filterEnabled = filterCount > 0;
    return (
      <NtTouchableEffect
        style={[
          {
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          },
          containerStyle,
        ]}
        onPress={onFilterPress}
      >
        <NtMaterialIcon
          name={"filter"}
          color={filterEnabled ? colors.blue : colors.almostBlack}
        />
        {filterEnabled > 0 && (
          <NtText
            style={{
              marginTop: 5,
              marginLeft: -3,
              color: colors.blue,
              fontWeight: "700",
            }}
          >
            {filterCount}
          </NtText>
        )}
      </NtTouchableEffect>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon name="magnify" color={colors.lighGray} size={24} />
      <TextInput
        style={[styles.input]}
        placeholder="Search"
        placeholderTextColor={colors.lighGray}
        {...otherProps}
      />

      {renderDelete()}

      {renderFilter({ marginLeft: 5 })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: colors.lighestGray,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 20,
  },
  input: {
    marginRight: 10,
    marginLeft: 10,
    flex: 1,
    paddingVertical: 0,
    outlineColor: colors.lighter,
    outlineStyle: "none",
  },
});

export default NtSearchBar;
