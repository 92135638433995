import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { createExtension } from "../../api/extension";
import {
  parseDisplayDate,
  parseSimpleDisplayDate,
  sleep,
} from "../../common/utils/ControllerUtils";
import { createAppAccess } from "../../api/appaccess";
import { createPin } from "../../api/billing";
import terminationcausedata from "../data/terminationcausedata";

export const handlePinUploadCreate = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        const success = [];
        const failure = [];

        results.data.map(async (each, index) => {
          const response = await createPin(
            environment?.domain,
            each.number,
            each.name,
            each.expirationdate,
            each.type,
            each.credit
          );

          Logger("Pin upload POST", response);

          //slowing down the request
          await sleep(500);

          if (response.ok) {
            success.push(each);
          } else {
            failure.push({
              request: each,
              status: response.status,
              error: response.data,
            });
          }

          if (success.length + failure.length == results.data.length) {
            resolve({
              ok: true,
              success: success,
              failure: failure,
              total: results.data.length,
            });
          }
        });
      },
    });
  });
};

export const handlePinUploadFailure = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};

export const handleCSVDownloadData = async (data, columns) => {
  return new Promise((resolve, reject) => {
    const handleColumnData = (item, column) => {
      if (column == "creationdate" || column == "expirationdate") {
        return parseSimpleDisplayDate(item[column]);
      } else if (column == "starttime" || column == "stoptime") {
        return `"${parseDisplayDate(item[column])}"`;
      } else if (column == "terminatecauseid") {
        const termination = terminationcausedata.find(
          (each) => each.id == item[column]
        );

        return termination?.title || "Unknown";
      }
      return item[column];
    };

    let csvData = "";

    //first... will put all the column names...
    columns.map((each) => {
      csvData += `${each.title},`;
    });
    csvData = csvData.substring(0, csvData.length - 1);

    //next... will start adding the data.....

    data?.map((item) => {
      let row = "";
      columns.map((eachColumn) => {
        row += `${handleColumnData(item, eachColumn.id)},`;
      });
      row = row.substring(0, row.length - 1);
      csvData += `\n${row}`;
    });

    resolve({ ok: true, data: csvData });
  });
};
