const terminationcausedata = [
  {
    id: 1,
    title: "Answered",
  },
  {
    id: 2,
    title: "Busy",
  },
  {
    id: 4,
    title: "Canceled",
  },
  {
    id: 7,
    title: "Limit Reach",
  },
];
export default terminationcausedata;
