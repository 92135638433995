import React, { useState } from "react";
import { View, StyleSheet, TextInput, LogBox } from "react-native";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import colors from "../config/colors";
import NtText from "./NtText";
import NtTextInput from "./NtTextInput";
import NtDropDownSelectContainer from "./NtDropDownSelectContainer";
import NtModalUnactiveButton from "./NtModalUnactiveButton";
import NtModalActiveButton from "./NtModalActiveButton";
import { parseDisplayDate } from "../common/utils/ControllerUtils";
import Logger from "../common/utils/Logger";

function NtDatePickerModal({
  title = "Date Picker",
  subtitle = "Select date below",
  isTimeEnabled = false,
  date,
  onSelect,
  onCancel,
}) {
  const options = [
    { value: "01:00:00", title: "01:00 AM" },
    { value: "02:00:00", title: "02:00 AM" },
    { value: "03:00:00", title: "03:00 AM" },
    { value: "04:00:00", title: "04:00 AM" },
    { value: "05:00:00", title: "05:00 AM" },
    { value: "06:00:00", title: "06:00 AM" },
    { value: "07:00:00", title: "07:00 AM" },
    { value: "08:00:00", title: "08:00 AM" },
    { value: "09:00:00", title: "09:00 AM" },
    { value: "10:00:00", title: "10:00 AM" },
    { value: "11:00:00", title: "11:00 AM" },
    { value: "12:00:00", title: "12:00 PM" },
    { value: "13:00:00", title: "01:00 PM" },
    { value: "14:00:00", title: "02:00 PM" },
    { value: "15:00:00", title: "03:00 PM" },
    { value: "16:00:00", title: "04:00 PM" },
    { value: "17:00:00", title: "05:00 PM" },
    { value: "18:00:00", title: "06:00 PM" },
    { value: "19:00:00", title: "07:00 PM" },
    { value: "20:00:00", title: "08:00 PM" },
    { value: "21:00:00", title: "09:00 PM" },
    { value: "22:00:00", title: "10:00 PM" },
    { value: "23:00:00", title: "11:00 PM" },
  ];

  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedTime, setSelectedTime] = useState(options[0]);

  const isRequiredFields = () => {
    return selectedDate && selectedTime;
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>{title}</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6, marginBottom: 25 }]}>
        {subtitle}
      </NtText>

      {isTimeEnabled && (
        <div style={{ maxWidth: 200, marginBottom: 25 }}>
          <NtDropDownSelectContainer
            containerStyle={{}}
            title={"Select Time *"}
            data={options}
            selection={selectedTime}
            onSelect={(option) => {
              setSelectedTime(option);
            }}
          />
        </div>
      )}

      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={setSelectedDate}
        defaultMonth={selectedDate}
      />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"Select"}
          onPress={() => {
            if (isRequiredFields()) {
              if (isTimeEnabled) {
                selectedDate.setHours(
                  selectedTime.value.split(":")[0],
                  selectedTime.value.split(":")[1],
                  selectedTime.value.split(":")[2]
                );
              }

              onSelect(selectedDate);
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  input: {
    marginRight: 10,
    marginLeft: 6,
    flex: 1,
    paddingVertical: 0,
    outlineColor: colors.lighter,
    outlineStyle: "none",
  },
});

export default NtDatePickerModal;
