import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtDevider from "../../components/NtDevider";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import useSelection from "../../hooks/useSelection";
import moment from "moment";
import { handleCSVDownloadData } from "../utils/PinUploadParser";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import pincsvcolumndata from "../data/pincsvcolumdata";
import cdrcsvcolumndata from "../data/cdrcsvcolumndata";

function NtAdjustColumnsButtonFieldPicker({
  onCancel,
  onUpdate,
  columns,
  type = "pin",
}) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const selection = useSelection();

  let data = pincsvcolumndata;
  if (type == "cdr") {
    data = cdrcsvcolumndata;
  }

  const isRequiredFields = () => {
    return selection.selections?.length > 0;
  };

  useEffect(() => {
    //will set the defaults....
    let defaults = [];
    data.map((each) => {
      if (columns?.find((eachColumn) => each.id == eachColumn.id)) {
        defaults.push(each.id);
      }
    });
    selection.setSelections(defaults);
  }, []);

  const handleUpdate = () => {
    let selectedColumns = [];
    data.map((each) => {
      if (selection.isSelected(each.id)) {
        selectedColumns.push(each);
      }
    });

    onUpdate(selectedColumns);
  };

  const handleDefault = () => {
    let defaults = [];
    data.map((each) => {
      if (each.default == true) {
        defaults.push(each.id);
      }
    });
    selection.setSelections(defaults);
  };

  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={item.id}
        onPress={() => {
          if (selection.isSelected(item.id)) {
            selection.removeSelection(item.id);
          } else {
            selection.addSelection(item.id);
          }
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NtText
            style={[
              styles.rowText,
              {
                flex: 1,
                color: selection.isSelected(item.id)
                  ? colors.darkest
                  : colors.lighGray,
              },
            ]}
          >
            {item.title}
          </NtText>
          {selection.isSelected(item.id) && (
            <NtMaterialIcon name="check" size={20} color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Manage Display Columns</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select the columns you wish to display.
      </NtText>

      <View style={{ marginTop: 20, marginBottom: 20 }}>
        {data.map((each, index) => renderRow(each, index))}
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalActiveButton
          containerStyle={{}}
          title={"Default"}
          onPress={() => {
            handleDefault();
          }}
          enabled={true}
        />

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Update Selection"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default NtAdjustColumnsButtonFieldPicker;
