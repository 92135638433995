import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";
import Logger from "../common/utils/Logger";
import MessageItem from "../maritime/utils/Message/MessageItem";
import moment from "moment-timezone";

export const fetchPins = async (domain, page, pageSize, search, filter) => {
  let endpoint = `/callingcard/pin/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.status == "active") {
    endpoint += "&active=1";
  } else if (filter?.status == "inactive") {
    endpoint += "&active=0";
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchCdrs = async (domain, page, pageSize, search, filter) => {
  let endpoint = `/callingcard/cdrs/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.uniqueid) {
    endpoint += `&uniqueid=${filter?.uniqueid}`;
  }

  if (filter?.terminatecauseid) {
    endpoint += `&terminatecauseid=${filter?.terminatecauseid}`;
  }

  if (filter?.tag) {
    endpoint += `&tag=${filter?.tag}`;
  }

  if (filter?.dateFilterFrom) {
    endpoint += `&dateFilterFrom=${filter?.dateFilterFrom}`;
  }

  if (filter?.dateFilterTo) {
    endpoint += `&dateFilterTo=${filter?.dateFilterTo}`;
  }

  Logger("------- edpoint: ", endpoint);

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const createPin = async (
  domain,
  number,
  name,
  expirationDate,
  type,
  initialCredit
) => {
  const body = {
    number: number,
    name: name,
    expirationdate: moment(expirationDate).format("YYYY-MM-DD"),
    tag: type,
    initialbalance: initialCredit,
    status: 1,
  };
  const response = await client.post(`/callingcard/pin/domain/${domain}`, body);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const deletePin = async (domain, id) => {
  const response = await client.delete(
    `/callingcard/pin/domain/${domain}/${id}`
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updatePin = async (domain, id, expirationDate, credit) => {
  const body = {
    expirationdate: moment(expirationDate).format("YYYY-MM-DD"),
    initialbalance: credit,
    status: 1,
  };
  const response = await client.patch(
    `/callingcard/pin/domain/${domain}/${id}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
