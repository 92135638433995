import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import {
  parseDisplayDate,
  parseSimpleDisplayDate,
} from "../../common/utils/ControllerUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtPinItem from "./NtPinItem";
import NtModal from "../../components/NtModal";
import NtPinManagementUpdateForm from "./NtPinManagementUpdateForm";
import moment from "moment-timezone";
import Logger from "../../common/utils/Logger";

function NtPinManagementItem({ item, onUpdate, columns }) {
  const updateModalRef = useRef();

  const isActive = item.status == 1;

  const isExpired = () => {
    return moment(item.expirationdate) < new Date();
  };

  const renderAction = () => {
    return (
      <NtTouchableEffect
        style={{
          width: 30,
          height: 30,
          borderRadius: 15,
          backgroundColor: colors.lighestGray,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => {
          updateModalRef.current?.show();
        }}
      >
        {<NtMaterialIcon name={"pencil"} color={colors.blue} />}
      </NtTouchableEffect>
    );
  };

  const renderUpdateForm = () => {
    return (
      <NtModal
        ref={updateModalRef}
        renderBody={
          <NtPinManagementUpdateForm
            item={item}
            onCancel={() => {
              updateModalRef.current?.dismiss();
            }}
            onUpdate={() => {
              updateModalRef.current?.dismiss();
              if (onUpdate) {
                onUpdate();
              }
            }}
          />
        }
      />
    );
  };

  const renderRow = (column) => {
    if (column.id == "number") {
      // this is for pin.....
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtPinItem number={item[column.id]} enabled={item.status == 1} />
        </View>
      );
    } else if (column.id == "creationdate" || column.id == "expirationdate") {
      return (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          key={column.id}
        >
          <NtText
            style={[
              styles.text,
              {
                color:
                  item.status == 1 && moment(item[column.id]) < new Date()
                    ? "red"
                    : colors.lighGray,
              },
            ]}
          >
            {parseSimpleDisplayDate(item[column.id])}
          </NtText>
        </View>
      );
    }

    return (
      <View
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        key={column.id}
      >
        <NtText style={styles.text}>{item[column.id]}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {columns?.map((each) => renderRow(each))}

        <View style={{}}>{renderAction()}</View>
      </View>
      <NtDevider />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default NtPinManagementItem;
