const environmentsdata = [
  {
    name: "Cunard",
    code: "CU",
    apiUrl: "https://cunnard-svc.ntlk.co/v3",
    apiAuth: "bmNsOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://199.193.189.187:15674/ws",
    messagingService: "nettalkmaritime.com",
    domain: "cunarddev.ntlk.co",
    octet: 0,
  },
  {
    name: "Development (v4)",
    code: "DEV-v4",
    apiUrl: "https://ntmpbx-v4.ntlk.co/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://ntm-v4.ntlk.co:15674/ws",
    messagingService: "qa.hap.ntlk.co",
    domain: "qa.hap.ntlk.co",
    octet: 9,
  },
  {
    name: "Development (qa)",
    code: "QA",
    apiUrl: "https://qa.hap.ntlk.co/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://10.26.9.10:15674/ws",
    messagingService: "qa.hap.ntlk.co",
    domain: "qa.hap.ntlk.co",
    octet: 9,
  },
  {
    name: "Copper River Princess Lodge",
    code: "CPL",
    apiUrl: "http://cpl.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://cpl.hap.ntlk.co:15674/ws",
    messagingService: "cpl.hap.ntlk.co",
    domain: "cpl.hap.ntlk.co",
    octet: 11,
  },
  {
    name: "Kenai Princess Lodge",
    code: "KPL",
    apiUrl: "http://kpl.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://kpl.hap.ntlk.co:15674/ws",
    messagingService: "kpl.hap.ntlk.co",
    domain: "kpl.hap.ntlk.co",
    octet: 12,
  },
  {
    name: "Denali Princess Lodge",
    code: "DPL",
    apiUrl: "http://dpl.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://dpl.hap.ntlk.co:15674/ws",
    messagingService: "dpl.hap.ntlk.co",
    domain: "dpl.hap.ntlk.co",
    octet: 13,
  },
  {
    name: "Fairbanks Princess Lodge",
    code: "FPH",
    apiUrl: "http://fph.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://fph.hap.ntlk.co:15674/ws",
    messagingService: "fph.hap.ntlk.co",
    domain: "fph.hap.ntlk.co",
    octet: 14,
  },
  {
    name: "Westmark Fairbanks Hotel",
    code: "FAW",
    apiUrl: "http://faw.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://faw.hap.ntlk.co:15674/ws",
    messagingService: "faw.hap.ntlk.co",
    domain: "faw.hap.ntlk.co",
    octet: 15,
  },
  {
    name: "McKinley Princess Lodge",
    code: "MPL",
    apiUrl: "http://mpl.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://mpl.hap.ntlk.co:15674/ws",
    messagingService: "mpl.hap.ntlk.co",
    domain: "mpl.hap.ntlk.co",
    octet: 16,
  },
  {
    name: "McKinley Chalet",
    code: "CHT",
    apiUrl: "http://cht.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://cht.hap.ntlk.co:15674/ws",
    messagingService: "cht.hap.ntlk.co",
    domain: "cht.hap.ntlk.co",
    octet: 17,
  },
  {
    name: "Westmark Inn Skagway",
    code: "SGW",
    apiUrl: "http://sgw.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://sgw.hap.ntlk.co:15674/ws",
    messagingService: "sgw.hap.ntlk.co",
    domain: "sgw.hap.ntlk.co",
    octet: 18,
  },
  {
    name: "Westmark Dawson",
    code: "YDW",
    apiUrl: "http://ydw.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://ydw.hap.ntlk.co:15674/ws",
    messagingService: "ydw.hap.ntlk.co",
    domain: "ydw.hap.ntlk.co",
    octet: 19,
  },
  {
    name: "EWC",
    code: "EWC",
    apiUrl: "http://ewc.hap.ntlk.co:31184/api/v1",
    apiAuth: "aGFwOjZlOTk5ZGViYWQyNTViNjE5N2Y0ZmI0NGE1ZTc1NzIzZTU1MGM5M2Q=",
    stompUrl: "ws://ewc.hap.ntlk.co:15674/ws",
    messagingService: "ewc.hap.ntlk.co",
    domain: "ewc.hap.ntlk.co",
    octet: 1,
  },
];

export default environmentsdata;
