import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import moment from "moment-timezone";
import NtModal from "./NtModal";
import NtDatePickerModal from "./NtDatePickerModal";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";
import colors from "../config/colors";
import {
  parseDisplayDate,
  parseSimpleDisplayDate,
} from "../common/utils/ControllerUtils";
import Logger from "../common/utils/Logger";

function NtModalDatePickerField({
  containerStyle,
  title,
  placeholder,
  value,
  setValue,
  isTimeEnabled = false,
}) {
  const datePickerRef = useRef(null);

  const renderDatePickerModal = () => {
    return (
      <NtModal
        ref={datePickerRef}
        renderBody={
          <NtDatePickerModal
            date={value}
            isTimeEnabled={isTimeEnabled}
            onSelect={(date) => {
              setValue(date);
              datePickerRef.current?.dismiss();
            }}
            onCancel={() => {
              datePickerRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  const formatDate = () => {
    if (value) {
      if (isTimeEnabled) {
        return parseDisplayDate(value);
      } else {
        return parseSimpleDisplayDate(value);
      }
    }
    return placeholder;
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.title, { marginLeft: 10 }]}>{title}</NtText>
      <NtTouchableEffect
        style={[
          {
            paddingLeft: 10,
            paddingRight: 10,
            height: 45,
            backgroundColor: colors.lighestGray,
            borderRadius: 30,
            justifyContent: "center",
            marginTop: 4,
          },
        ]}
        onPress={() => {
          datePickerRef.current.show();
        }}
      >
        <NtText
          style={{
            fontSize: 14,
            fontWeight: "500",
            color: value ? colors.almostBlack : colors.lighGray,
            marginLeft: 4,
          }}
        >
          {formatDate()}
        </NtText>
      </NtTouchableEffect>
      {renderDatePickerModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtModalDatePickerField;
