import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtModal from "../../components/NtModal";
import NtAdjustColumnsButtonFieldPicker from "./NtAdjustColumnsButtonFieldPicker";

function NtAdjustColumnsButton({ columns, onUpdate, type = "pin" }) {
  const fieldsSelectRef = useRef();

  const renderFieldsSelectModal = () => {
    return (
      <NtModal
        ref={fieldsSelectRef}
        renderBody={
          <NtAdjustColumnsButtonFieldPicker
            onCancel={() => {
              fieldsSelectRef.current?.dismiss();
            }}
            columns={columns}
            type={type}
            onUpdate={(selected) => {
              if (onUpdate) {
                onUpdate(selected);
              }
              fieldsSelectRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={styles.container}
      onPress={() => {
        fieldsSelectRef.current?.show();
      }}
    >
      <NtMaterialIcon name="cog" color="white" />
      <NtText
        style={{
          color: "white",
          fontWeight: "600",
          fontSize: 13,
          marginLeft: 4,
        }}
      >
        Manage Display Columns
      </NtText>
      {renderFieldsSelectModal()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 8,
    backgroundColor: colors.blue,
    borderRadius: 20,
  },
});

export default NtAdjustColumnsButton;
