import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import colors from "../../config/colors";
import { fetchCdrs } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import NtPinCdrItem from "../components/NtPinCdrItem";
import NtPinCdrItemHeader from "../components/NtPinCdrItemHeader";
import NtDownloadCSVButton from "../components/NtDownloadCSVButton";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtModal from "../../components/NtModal";
import NtPinCdrAdvanceSearchPicker from "../components/NtPinCdrAdvanceSearchPicker";
import settings from "../../config/settings";
import BasePinController from "./BasePinController";
import cdrcsvcolumndata from "../data/cdrcsvcolumndata";

class PinHistoryController extends BasePinController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
  };

  column_location = "@cdr_column_storage";
  page = 1;

  advanceSearchRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      cdrcsvcolumndata.filter((each) => each.default == true)
    );
    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    Logger("Fetching CDR history data");

    this.setState({ loading: true });

    const response = await fetchCdrs(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      this.state.searchFilter
    );
    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.cdrs?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.cdrs,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.cdrs),
        });
      }
    }

    this.setState({ loading: false });
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAdvanceSearchForm = () => {
    return (
      <NtModal
        ref={this.advanceSearchRef}
        renderBody={
          <NtPinCdrAdvanceSearchPicker
            filter={this.state.searchFilter}
            onCancel={() => {
              this.advanceSearchRef.current?.dismiss();
            }}
            onFilter={(filter) => {
              this.state.searchFilter = filter;
              this.setState({ searchFilter: this.state.searchFilter });
              this.advanceSearchRef.current?.dismiss();

              this.page = 1;
              this.handleDataFetch();
            }}
            onFilterClear={() => {
              this.state.searchFilter = null;
              this.setState({ searchFilter: this.state.searchFilter });
              this.advanceSearchRef.current?.dismiss();

              this.page = 1;
              this.handleDataFetch();
            }}
          />
        }
      />
    );
  };

  renderSearchAction = () => {
    return (
      <NtTouchableEffect
        style={{
          marginLeft: 5,
          width: 35,
          height: 35,
          backgroundColor: colors.lighestGray,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 20,
        }}
        onPress={() => {
          this.advanceSearchRef?.current?.show();
        }}
      ></NtTouchableEffect>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtPinCdrItem key={item.id} item={item} columns={this.state.columns} />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Pin History"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        searchFilterCount={
          this.state.searchFilter
            ? Object.keys(this.state.searchFilter)?.length
            : 0
        }
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        onSearchFilterPress={() => {
          this.advanceSearchRef?.current?.show();
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtText style={styles.title}>
            Records Found {this.state.totalItems}
          </NtText>

          <NtDownloadCSVButton
            containerStyle={{ marginLeft: 20 }}
            type={"cdr"}
            search={this.state.searchText}
            filter={this.state.searchFilter}
          />
        </View>

        {this.renderAdjustColumnsButton("cdr")}

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          {this.state.data?.length > 0 && (
            <NtPinCdrItemHeader columns={this.state.columns} />
          )}
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAdvanceSearchForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default PinHistoryController;
